import React from 'react';
import styled from 'styled-components';
import Container from './lib/Container';
import Section from './lib/Section';
import SectionTitle from './lib/SectionTitle';
import StackCards from './lib/StackCards';

const Paragraph = styled.p`
  max-width: 40rem;
`;

const TechStack = (props) => {
  const stack = [
    'React Native',
    'React.js',
    'Swift',
    'Java',
    'Node.js',
    'Laravel',
    'PHP',
    'JavaScript',
    'Vue.js',
    'HTML5',
    'MySQL',
    'SQLite',
    'XCode',
    'AdobeXd',
    'Android Studio',
  ];

  return (
    <Section gradient>
      <Container>
        <SectionTitle title={'Technology Stack'} />
        <Paragraph>
          Since beginning my journey as a developer nearly 7 years ago,
          I've done remote work for agencies, consulted for startups, and
          collaborated with talented people to create digital products for both business
          and consumer use. I'm quietly confident, naturally curious, and perpetually working
          on improving my skills one project at a time.
        </Paragraph>

        <StackCards {...{ stack }} />
      </Container>
    </Section>
  );
};

export default TechStack;
