import React from 'react'

const AdobeXdLogo = (props) => {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 48 48'>
			<path
				fill='#c767e5'
				d='M36 6H12a6 6 0 00-6 6v24a6 6 0 006 6h24a6 6 0 006-6V12a6 6 0 00-6-6z'
			/>
			<linearGradient
				id='eCzMVa~gw8dr5MHqWyNHVa'
				x1='8'
				x2='40'
				y1='24'
				y2='24'
				gradientUnits='userSpaceOnUse'>
				<stop offset='0' stopColor='#7819a2' />
				<stop offset='1' stopColor='#771aa9' />
			</linearGradient>
			<path
				fill='url(#eCzMVa~gw8dr5MHqWyNHVa)'
				d='M36 40H12a4 4 0 01-4-4V12a4 4 0 014-4h24a4 4 0 014 4v24a4 4 0 01-4 4z'
			/>
			<path
				fill='#fff'
				d='M24.209 31.946h-2.334a.501.501 0 01-.464-.313l-2.102-5.206c-.084-.205-.171-.583-.262-1.135h-.036c-.042.261-.142.654-.298 1.182l-2.11 5.161a.5.5 0 01-.463.311h-2.339a.5.5 0 01-.45-.719l3.672-7.544a.498.498 0 00.007-.422l-3.364-7.557a.501.501 0 01.458-.704h2.426a.5.5 0 01.47.329l1.73 4.752c.145.402.274.879.389 1.43h.036a20.787 20.787 0 01.415-1.499l1.921-4.7a.498.498 0 01.462-.312h2.132a.5.5 0 01.454.71l-3.456 7.481a.5.5 0 00.001.421l3.558 7.622a.5.5 0 01-.453.712zm9.293-.223h-1.675a.5.5 0 01-.5-.5v-1.055h-.034c-.61 1.221-1.505 1.831-2.684 1.831-1.084 0-1.956-.498-2.617-1.495-.66-.997-.991-2.388-.991-4.176 0-1.868.365-3.365 1.092-4.49.728-1.126 1.682-1.688 2.862-1.688 1.118 0 1.897.522 2.338 1.567h.034v-6.218a.5.5 0 01.5-.5h1.675a.5.5 0 01.5.5v15.724a.5.5 0 01-.5.5zm-2.125-5.494v-.849c0-.735-.164-1.346-.491-1.831-.327-.485-.753-.728-1.278-.728-.593 0-1.056.303-1.389.91-.333.607-.499 1.44-.499 2.499 0 .993.161 1.758.483 2.295.322.537.768.806 1.338.806.542 0 .983-.283 1.325-.85.341-.567.511-1.318.511-2.252z'
			/>
		</svg>
	)
}

export default AdobeXdLogo
