import React from 'react'
const SwiftLogo = (props) => {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 48 48'>
			<path
				fill='#FF6D00'
				d='M6 10a4 4 0 014-4h28a4 4 0 014 4v28a4 4 0 01-4 4H10a4 4 0 01-4-4V10z'
			/>
			<path
				fill='#E64A19'
				d='M6 10v11.967l4 4S21.153 38.848 30 30s-3.981-19-3.981-19l-5.005-5H10a4 4 0 00-4 4z'
			/>
			<path
				fill='#FFF'
				d='M26.019 11c11.429 7.698 7.732 16.187 7.732 16.187s3.25 3.634 1.936 6.813c0 0-1.34-2.226-3.588-2.226-2.166 0-3.44 2.226-7.8 2.226C14.591 34 10 25.967 10 25.967c8.747 5.7 14.719 1.663 14.719 1.663C20.778 25.364 12 14 12 14c7.299 6.155 11 8 11 8-1.882-1.542-8-9-8-9 4.224 4.238 13.307 10.378 13.307 10.378C30.691 16.834 26.019 11 26.019 11z'
			/>
		</svg>
	)
}

export default SwiftLogo
