import React from 'react'

const TensorflowLogo = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      strokeMiterlimit='1.414'
      fillRule='evenodd'
      strokeLinejoin='round'
      viewBox='0 0 64 64'
    >
      <path
        d='m27.733 34.453 8.64-5.013 8.533 5.013-8.533 4.907-8.64-4.8zM1.813 19.627 36.373 0 62.08 14.613 53.547 29.44l-17.173-9.813-25.92 14.72z'
        fill='#f6bd3a'
        fillRule='nonzero'
      />
      <path
        d='M27.733 54.08v-9.813l8.533-4.907 8.64-4.907v9.813l-8.533 4.907v9.813L27.733 64zm0-19.627-8.64-4.907-8.64 4.8v-9.813l25.92-14.72V29.44zm25.707-9.92v-4.907l8.64-5.013.107 9.813-8.64 5.013z'
        fill='#eb8c23'
        fillRule='nonzero'
      />
      <path
        d='M19.093 58.773V29.547l8.533-4.8.107 9.707 8.64 4.8v9.92l-8.64-4.587V64zM6.08 31.787l-4.267-2.56v-9.6l8.64 4.907v9.813l-4.373-2.56zm30.293-12.16V9.813l17.067 9.813.107 9.813-17.173-9.813z'
        fill='#e35a2b'
        fillRule='nonzero'
      />
    </svg>
  )
}

export default TensorflowLogo
