import React from 'react'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import styled from 'styled-components'
import breakpoints from './../../commons/breakpoints'

const StyledTestimonialCard = styled.div`
	border: 5px solid ${({ theme }) => theme.colors.lightYellow};
	max-width: 30rem;
	margin: 1rem auto;
	padding: 1rem;
	text-align: center;

	@media ${breakpoints.device.tablet} {
		max-width: 50rem;
		text-align: left;
		margin-top: 3rem;
		padding: 2rem;
	}
`

const ClientName = styled.p`
	font-weight: 700;
	margin-top: 1rem;
`

const TestimonialCard = ({ testimonial }) => {
	return (
		<StyledTestimonialCard>
			<p role='presentation' aria-label='client rating'>
				⭐⭐⭐⭐⭐
			</p>
			<MDXRenderer>{testimonial.body}</MDXRenderer>
			<ClientName>- {testimonial.frontmatter.clientName}</ClientName>
		</StyledTestimonialCard>
	)
}

export default TestimonialCard
