import React from 'react'

const CodingIllustration = (props) => {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 501 598.845'>
			<g
				transform='translate(239.829 342.854)'
				strokeMiterlimit='10'
				strokeWidth='6'>
				<path
					fill='none'
					stroke='#ffb000'
					d='M24.522 29.838v222.634M122.887 29.838v222.634M23.571 216.681h98.112'
				/>
				<rect
					width='146.458'
					height='29.838'
					rx='14.919'
					fill='#fff'
					stroke='#6562cf'
				/>
			</g>
			<path
				d='M360.313 127.903c8.094-2.271 19.287-27.4 7.023-41.017s-38.248-14.023-60.308-10.719c-20.391 3.055-47.174 14.883-36.967 40.4 14.519 36.268 90.252 11.336 90.252 11.336z'
				fill='#ffb819'
			/>
			<path
				d='M348.115 110.787c7.65-1.616 20.793-14.343 9.242-24.029s-29.341-10.628-48.289-7.391-38.583 9.935-29.571 28.3 68.618 3.12 68.618 3.12z'
				fill='#f49c00'
			/>
			<path
				d='M393.263 593.112c-4.948 3.046-35.212 2.869-40.926 0s-4.375-12.564-5.326-19.038-14.467-18.646-15.228-22.839 13.894-13.325 16.437-17.044c-2.094 6.177 18.871 7.53 20.558 3.528-.822 14.659 9.949 37.961 18.785 40.065 5.562 1.33 10.649 12.283 5.7 15.328z'
				fill='#dbdaff'
			/>
			<path
				d='M373.292 521.689l-4.523 16.031c-1.678 4-22.643 2.649-20.558-3.528s1.3-16.911 1.3-16.911z'
				fill='#c36f0b'
			/>
			<path
				d='M331.788 551.255c.765 4.188 14.276 16.37 15.232 22.844s-.382 16.179 5.326 19.033 35.977 3.046 40.926 0'
				fill='none'
				stroke='#6562cf'
				strokeLinecap='round'
				strokeMiterlimit='10'
				strokeWidth='7'
			/>
			<path
				d='M369.946 577.765s10.04-4.485 20.033-.956'
				fill='none'
				stroke='#fff'
				strokeLinecap='round'
				strokeMiterlimit='10'
				strokeWidth='6'
			/>
			<g>
				<path
					d='M255.353 593.113c4.948 3.046 31.866 2.869 37.579 0s9.156-12.564 10.107-19.038 10.164-21.037 10.925-25.23-13.894-13.325-16.437-17.044c2.094 6.177-18.393 9.921-20.08 5.919.822 14.659-7.564 37.961-16.394 40.065-5.566 1.329-10.648 12.283-5.7 15.328z'
					fill='#dbdaff'
				/>
				<path
					d='M267.196 520.733l10.26 16.987c1.678 4 22.165.258 20.08-5.919s-2.73-15.954-2.73-15.954z'
					fill='#c36f0b'
				/>
				<path
					d='M313.965 548.865c-.76 4.188-9.973 18.761-10.925 25.234s-4.4 16.179-10.112 19.033-32.626 3.046-37.579 0'
					fill='none'
					stroke='#6562cf'
					strokeLinecap='round'
					strokeMiterlimit='10'
					strokeWidth='7'
				/>
				<path
					d='M278.671 577.766s-10.016-4.485-20.033-.956'
					fill='none'
					stroke='#fff'
					strokeLinecap='round'
					strokeMiterlimit='10'
					strokeWidth='6'
				/>
			</g>
			<path
				d='M264.447 278.409s-99.57 48.767-99.57 78.763c0 53.509 89.056 166.38 104.351 164.9s30.36-4.719 29.772-11.192-32.613-107.971-57.507-138.024c0 0 9.084-14.3 65.849-13.674 27.357.3 51.21 2.161 51.8 3.046s-29.745 60.081-14.268 151.606c1.353 7.975 34.705 11.322 37.938 6.909s55-159.835 50-172.2-56.894-56.665-82.205-70.143c-18.226-9.704-86.16.009-86.16.009z'
				fill='#3f4d79'
			/>
			<g>
				<path
					d='M236.014 123.767c-12.627 6.932-31.5 69.1-31.5 69.1s-45.465-55.808-47.569-56.331-14.319 15.777-14.319 15.777 23.738 105.986 60.767 108.343c15.543.99 28.686-8.027 39.477-21.2a945.87 945.87 0 009.247 29.093c1.912 6.693-33.773 25.124-25.631 30.187 0 0 27.783-9.036 76.807 3.6 58.616 15.127 86.953 3.653 81.411-.746s-14.243-18.7-17.25-28.266l5.407-51.482s18.923 48.03 47.528 48.03c34.122 0 65.309-104.318 65.309-104.318-12.129-.344-22.179-9.008-22.179-9.008-12.823 9.008-39.51 40.562-39.51 40.562s-16.633-50.282-24.6-61.718-33.945-20.448-51.291-24.6l-68.618-3.122c-4.607-.883-32.743 8.76-43.486 16.099z'
					fill='#ffb000'
				/>
				<path
					d='M424.009 195.005c-8.678 9.978-16.518 27.73-16.518 27.73M369.501 118.366c-33.314-2.816-66.762 18.139-66.762 18.139s-29.4-6.588-35.284-26.162'
					fill='none'
					stroke='#f49c00'
					strokeMiterlimit='10'
					strokeWidth='6'
				/>
				<path
					d='M253.073 268.543s-18.388-52.821-20.955-75.1'
					fill='none'
					stroke='#f49c00'
					strokeLinecap='round'
					strokeMiterlimit='10'
					strokeWidth='6'
				/>
			</g>
			<g>
				<path
					d='M484.739 127.497c-.445 2.845-3.538 7.258-.478 7.224 1.578 0 12.985-1.86 14.381 2.338a9.161 9.161 0 01-.3 6.058 6.368 6.368 0 01.588 6.784c1.511 2.324 1.912 5.331.861 6.655 0 0 2.644 6.1.12 7.511-7.822 4.4-20.3 5.312-26.339 3.748-4.241-1.095-9.328-3.715-10.628-12.741-.536-3.7 3.146-13.387 5.957-17.384 1.147-1.645 5.35-8.2 6.77-10a21.231 21.231 0 004.026-8.964s6.282.825 5.042 8.771z'
					fill='#c36f0b'
				/>
				<path
					d='M494.964 136.5c.277 1.912-11.374 2.314-13.387 2.754-2.869.622-2.5 6.746 1.224 6.148a77.728 77.728 0 0015.008-2.166'
					fill='none'
					stroke='#b65a18'
					strokeLinecap='round'
					strokeMiterlimit='10'
					strokeWidth='4'
				/>
				<path
					d='M498.165 149.963c-12.325 2.486-15.615 2.257-15.615 2.257-1.8.354-4.112-6 .249-6.818'
					fill='none'
					stroke='#b65a18'
					strokeLinecap='round'
					strokeMiterlimit='10'
					strokeWidth='4'
				/>
				<path
					d='M498.474 156.341c-11.6 2.548-15.615 2.257-15.615 2.257-1.716.206-3.863-5.838-.359-6.254'
					fill='none'
					stroke='#b65a18'
					strokeLinecap='round'
					strokeMiterlimit='10'
					strokeWidth='4'
				/>
				<path
					d='M486.105 158.497s-4.145-.387-4.083 2.194c.115 4.59 2.338 5.082 12.832 3.156'
					fill='none'
					stroke='#b65a18'
					strokeLinecap='round'
					strokeMiterlimit='10'
					strokeWidth='4'
				/>
				<path
					d='M140.35 113.389c.1 2.869 2.606 7.583-.4 7.172-1.559-.21-12.579-3.409-14.463.555a9.084 9.084 0 00-.44 6.01 6.334 6.334 0 00-1.406 6.617c-1.769 2.108-2.529 5.025-1.654 6.459 0 0-3.347 5.685-1.033 7.387 7.172 5.288 19.363 7.7 25.512 6.894 4.3-.564 9.643-2.529 12.024-11.269.956-3.576-1.477-13.554-3.758-17.857-.932-1.764-4.279-8.74-5.465-10.686a21.189 21.189 0 01-2.869-9.323s-6.307.059-6.048 8.041z'
					fill='#c36f0b'
				/>
				<g>
					<path
						d='M125.959 147.999c10.112 3.175 12.364 2.955 13.038-1.554.21-1.434-1.009-2.089-2.113-2.391a14.151 14.151 0 001.544.215c1.539.378 4.145-4.867 1.836-6.316.956-.478 1.97-3.906 1.965-6.952 1.783-1.234 1.912-4.891-.163-5.622-1.955-.684-13.387-2.505-12.909-4.351z'
						fill='#c36f0b'
					/>
					<path
						d='M126.14 127.32a78.8 78.8 0 0013.97 3.959c3.591 1.037 4.7-4.953 1.955-5.914-1.955-.684-11.1-3.662-12.909-4.346'
						fill='none'
						stroke='#b65a18'
						strokeLinecap='round'
						strokeMiterlimit='10'
						strokeWidth='3'
					/>
					<path
						d='M124.974 133.909c11.847 3.944 14.553 4.121 14.553 4.121 1.726.564 4.781-5.412.583-6.751'
						fill='none'
						stroke='#b65a18'
						strokeLinecap='round'
						strokeMiterlimit='10'
						strokeWidth='3'
					/>
					<path
						d='M123.334 140.134c11.121 3.925 15.118 4.121 15.118 4.121 1.669.416 4-5.852-.1-6.392'
						fill='none'
						stroke='#b65a18'
						strokeLinecap='round'
						strokeMiterlimit='10'
						strokeWidth='3'
					/>
					<path
						d='M135.234 143.782s4.136.124 3.763 2.663c-.674 4.509-2.921 4.724-13.033 1.554'
						fill='none'
						stroke='#b65a18'
						strokeLinecap='round'
						strokeMiterlimit='10'
						strokeWidth='3'
					/>
				</g>
			</g>
			<g>
				<path
					d='M333.562 66.08s-7.281 32.578 14.553 44.708c0 0-20.08 3.466-45.745 24.6 0 0-19.755-16.633-22.872-27.73 0 0 20.08-6.928 18.02-41.6z'
					fill='#c36f0b'
				/>
				<path
					d='M333.562 66.08h-36.044c2.08 34.658-18.02 41.6-18.02 41.6s40.079 0 51.415-35.733z'
					fill='#b65a18'
				/>
				<path
					d='M334.943 65.76s-10.7 20.869-36.283 20.879c-22.815 0-26.3-66.968 7.683-67.891 32.377-.88 28.208 37.431 28.208 37.431s5.828-3.39 7.946.139-7.554 9.442-7.554 9.442z'
					fill='#c36f0b'
				/>
				<path
					d='M319.95 21.248s-.368 11.594-16.418 11.417c-13.933-.157-21.577 7.736-22.323 13.416'
					fill='none'
					stroke='#3f4d79'
					strokeLinecap='round'
					strokeMiterlimit='10'
					strokeWidth='3'
				/>
				<path
					d='M363.96 44.145c0 12.632-15.247 23.551-32.129 30.962a10.507 10.507 0 01-4.661.378 39.762 39.762 0 007.769-9.725s9.615-6 7.535-9.443-7.946-.143-7.946-.143 2.921-26.7-14.955-35c-.139.258-4.781 8.443-18.5 8.878-13.052.406-16.614 5.383-18.106 10.585-.048.182-.1.359-.139.54a58.37 58.37 0 00-1.186 21.151l-.077-.029c-1.267-1.539-18.579-23.48-14.061-35.246 4.91-12.789 36.293-30.828 61.417-26.363 14.031 2.505 35.034 36.813 35.039 43.455z'
					fill='#3f4d79'
				/>
			</g>
			<g transform='translate(0 82.991)'>
				<path
					d='M250.166 142.198H57.61a12.627 12.627 0 01-11.953-8.606L4.846 12.072a8.878 8.878 0 018.419-11.7h192.557a12.631 12.631 0 0111.953 8.606l40.787 121.491a8.878 8.878 0 01-8.4 11.733z'
					fill='#c7bfff'
				/>
				<path
					d='M245.772 146.936H53.211a12.627 12.627 0 01-11.953-8.606L.466 16.834a8.878 8.878 0 018.415-11.7h192.547a12.627 12.627 0 0111.953 8.606l40.792 121.5a8.878 8.878 0 01-8.4 11.7z'
					fill='#fff'
				/>
				<path
					d='M250.166 141.806H57.61a12.622 12.622 0 01-11.953-8.606L4.846 11.704a8.878 8.878 0 018.419-11.7h192.557a12.631 12.631 0 0111.953 8.606l40.806 121.5a8.878 8.878 0 01-8.415 11.7z'
					fill='none'
					stroke='#6562cf'
					strokeMiterlimit='10'
					strokeWidth='6'
				/>
				<path
					d='M166.88 103.725l14.649 36.455h-31.66s6.153-28.519 17.011-36.455z'
					fill='#e2e2ff'
				/>
				<path
					d='M187.936 185.901c-62.34 0-36.126-66.71-23.728-80.565H116.23c-12.392 13.865-45.931 84.228 16.4 84.228h55.3z'
					fill='#fff'
					stroke='#6562cf'
					strokeMiterlimit='10'
					strokeWidth='6'
				/>
				<ellipse
					cx='9.113'
					cy='11.479'
					rx='9.113'
					ry='11.479'
					transform='rotate(-57.74 117.935 -65.928)'
					fill='#e2e2ff'
				/>
				<ellipse
					cx='9.113'
					cy='11.479'
					rx='9.113'
					ry='11.479'
					transform='rotate(-57.74 117.124 -69.996)'
					fill='none'
					stroke='#6562cf'
					strokeMiterlimit='10'
					strokeWidth='6'
				/>
				<path
					d='M44.806 189.563h285.509a7.361 7.361 0 017.6 7.1 7.36 7.36 0 01-7.6 7.09H44.806z'
					fill='#fff'
				/>
				<path
					d='M44.806 189.563h285.509a7.361 7.361 0 017.6 7.1h0a7.36 7.36 0 01-7.6 7.09H44.806'
					fill='none'
					stroke='#6562cf'
					strokeMiterlimit='10'
					strokeWidth='6'
				/>
			</g>
		</svg>
	)
}

export default CodingIllustration
