import React from 'react'

const VueLogo = (props) => {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 48 48'>
			<path
				fill='#81c784'
				d='M23.987 17l-5.253-9H2.974l21.013 36L45 8H29.24z'
			/>
			<path
				fill='#455a64'
				d='M29.24 8l-5.253 9-5.253-9h-7.588l12.841 22L36.828 8z'
			/>
		</svg>
	)
}

export default VueLogo
