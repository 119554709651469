import React from 'react'
import styled from 'styled-components'
import breakpoints from '../../commons/breakpoints'
import { getStackDetails } from '../../utilities/utils'
import PhpLogo from '../icons/Php'
import LaravelLogo from '../icons/Laravel'
import JsLogo from '../icons/Js'
import ReactLogo from '../icons/React'
import VueLogo from '../icons/Vue'
import HtmlLogo from '../icons/HtmlLogo'
import CssLogo from '../icons/Css'
import JavaLogo from '../icons/Java'
import SwiftLogo from '../icons/Swift'
import MySqlLogo from '../icons/Mysql'
import SQLiteLogo from '../icons/SqLite'
import XcodeLogo from '../icons/Xcode'
import AdobeXdLogo from '../icons/AdobeXd'
import AndroiStudioLogo from '../icons/AndroidStudio'
import MobXLogo from './../icons/MobX'
import BuddyLogo from './../icons/Buddy'
import NodejsLogo from './../icons/Node'
import TensorflowLogo from './../icons/Tensorflow'
import PythonLogo from './../icons/Python'
import ReduxLogo from './../icons/Redux'

const StyledStackCards = styled.ul`
  display: flex;
  flex-wrap: wrap;
  justify-content: ${({ project }) => (project ? 'center' : 'space-evenly')};
  margin-top: 1rem;

  @media ${breakpoints.device.tablet} {
    justify-content: ${({ project }) =>
      project ? 'flex-start' : 'space-between'};
  }
`
const Card = styled.li`
  flex-basis: 45%;
  max-width: ${({ project }) => (project ? '5rem' : '8rem')};
  box-shadow: 0 0 10px rgba(20, 0, 109, 0.1);
  border-radius: 3px;
  background: ${(props) => props.theme.colors.white};
  text-align: center;
  padding: ${({ project }) => (project ? '0.5rem' : '1rem')};
  margin: 3% 1%;
  font-size: ${({ project }) => (project ? '0.8rem' : '1rem')};
  color: ${({ theme }) => theme.colors.copyMain};

  & div {
    width: ${({ project }) => (project ? '40%' : '50%')};
    margin: auto;
  }

  @media ${breakpoints.device.tablet} {
    max-width: ${({ project }) => (project ? '6rem' : '11rem')};
    margin: 2% 1%;
  }
`

const StackCard = ({ name, project }) => {
  const techStack = [
    {
      name: 'PHP',
      logo: <PhpLogo />,
    },

    {
      name: 'Laravel',
      logo: <LaravelLogo />,
    },

    {
      name: 'JavaScript',
      logo: <JsLogo />,
    },
    {
      name: 'React.js',
      logo: <ReactLogo />,
    },
    {
      name: 'React Native',
      logo: <ReactLogo />,
    },

    {
      name: 'Vue.js',
      logo: <VueLogo />,
    },

    {
      name: 'HTML5',
      logo: <HtmlLogo />,
    },

    {
      name: 'CSS3',
      logo: <CssLogo />,
    },

    {
      name: 'Java',
      logo: <JavaLogo />,
    },
    {
      name: 'Swift',
      logo: <SwiftLogo />,
    },

    {
      name: 'MySQL',
      logo: <MySqlLogo />,
    },

    {
      name: 'SQLite',
      logo: <SQLiteLogo />,
    },

    {
      name: 'XCode',
      logo: <XcodeLogo />,
    },

    {
      name: 'AdobeXd',
      logo: <AdobeXdLogo />,
    },

    {
      name: 'Android Studio',
      logo: <AndroiStudioLogo />,
    },

    {
      name: 'MobX',
      logo: <MobXLogo />,
    },

    {
      name: 'Buddy.works',
      logo: <BuddyLogo />,
    },

    {
      name: 'Node.js',
      logo: <NodejsLogo />,
    },

    {
      name: 'Tensorflow',
      logo: <TensorflowLogo />,
    },

    {
      name: 'Python',
      logo: <PythonLogo />,
    },

    {
      name: 'Redux',
      logo: <ReduxLogo />,
    },
  ]
  const tech = getStackDetails(techStack, name)

  if (!tech) return <Card>{name}</Card>

  return (
    <Card project={project}>
      <div>{tech.logo}</div>
      <p>{tech.name}</p>
    </Card>
  )
}

const StackCards = ({ stack, project }) => {
  return (
    <StyledStackCards project={project}>
      {stack.map((tool) => (
        <StackCard name={tool} key={tool} project={project} />
      ))}
    </StyledStackCards>
  )
}

export default StackCards
