import React from 'react'

const HtmlLogo = (prop) => {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 48 48'>
			<path fill='#E65100' d='M41 5H7l3 34 14 4 14-4 3-34z' />
			<path fill='#FF6D00' d='M24 8v31.9l11.2-3.2L37.7 8z' />
			<path
				fill='#FFF'
				d='M24 25v-4h8.6l-.7 11.5-7.9 2.6v-4.2l4.1-1.4.3-4.5H24zm8.9-8l.3-4H24v4h8.9z'
			/>
			<path
				fill='#EEE'
				d='M24 30.9v4.2l-7.9-2.6-.4-5.5h4l.2 2.5 4.1 1.4zM19.1 17H24v-4h-9.1l.7 12H24v-4h-4.6l-.3-4z'
			/>
		</svg>
	)
}

export default HtmlLogo
