import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import Container from './lib/Container'
import Section from './lib/Section'
import SectionTitle from './lib/SectionTitle'
import styled from 'styled-components'
import TestimonialCard from './lib/TestimonialCard'
import breakpoints from './../commons/breakpoints'

const Paragraph = styled.p`
  max-width: 40rem;
`

const TestimonialsBox = styled(Container)`
  overflow-x: hidden;

  @media ${breakpoints.device.tablet} {
    overflow-x: visible;
  }
`

const Testimonials = (props) => {
  const { allMdx } = useStaticQuery(graphql`
    query TestimonialsQuery {
      allMdx(filter: { frontmatter: { type: { eq: "testimonial" } } }) {
        nodes {
          frontmatter {
            clientName
          }
          id
          body
        }
      }
    }
  `)

  const carouselSettings = {
    dots: true,
    autoplay: true,
    autoplaySpeed: 5000,
    speed: 1000,
  }

  return (
    <Section id='testimonials'>
      <TestimonialsBox>
        <SectionTitle title='Testiomonials' />
        <Paragraph>
          People I've worked with have said some nice things...
        </Paragraph>

        <Slider {...carouselSettings}>
          {allMdx.nodes.map((testimonial) => (
            <TestimonialCard key={testimonial.id} testimonial={testimonial} />
          ))}
        </Slider>
      </TestimonialsBox>
    </Section>
  )
}

export default Testimonials
