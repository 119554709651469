import React from 'react'
import Container from './lib/Container'
import FlexContainer from './lib/FlexContainer'
import Section from './lib/Section'
import CodingIllustration from './illustrations/Coding'
import styled from 'styled-components'
import breakpoints from './../commons/breakpoints'
import SectionTitle from './lib/SectionTitle'

const IllustrationBox = styled.div`
	width: 70%;
	max-width: ${breakpoints.size.mobile};
	position: relative;
	padding-right: 10%;
	margin-bottom: 5rem;

	&:after {
		content: '';
		width: 100%;
		height: 60%;
		position: absolute;
		bottom: -20%;
		left: 5%;
		background: ${(props) => props.theme.colors.greyishPurple};
		border-radius: 50%;
		z-index: -1;
	}

	@media ${breakpoints.device.tablet} {
		flex-basis: 35%;
		padding-right: 2%;

		&:after {
			left: 7%;
		}
	}
`
const AboutCopy = styled.article`
	@media ${breakpoints.device.tablet} {
		flex-basis: 60%;
	}
`

const About = (props) => {
	return (
		<Section id='about'>
			<Container>
				<FlexContainer>
					<IllustrationBox>
						<CodingIllustration />
					</IllustrationBox>
					<AboutCopy>
						<SectionTitle title={`Hi, I'm ${props.author.name}`} />
						<p id='about'>
							My devotion for developing applications spans far beyond just the
							basics. My services are tailor-made to fit every business, to
							glorify every brand and to bring ideas to life, delivering only
							the best for clients; an application that truly portrays whilst
							incorporating an exceptional, unique and state of the art design
							that stands out from the rest.
						</p>
						<br />
						<p id='about'>
							I have extensive knowledge of several platforms and languages such as PHP,
							Laravel Framework, JavaScript, React-Native, React, HTML/CSS, VueJs, Java
							and Swift as well as Android Studio, Xcode, Adobe XD, MySQL and SQLite.
							I aim to design and develop applications that can maintain a uniqueness while
							at the same time being user-friendly by working perfectly on UI/UX. I guarantee
							support, analysis and troubleshooting to make everything easy and understandable,
							meanwhile providing integration techniques into current environments.
						</p>
						<br />
						<p id='about'>
							What you desire, I will provide with utmost precision, dedication and an ability
							to think outside the norm and bring to the forefront something that will keep clients
							coming back for more.
						</p>
					</AboutCopy>
				</FlexContainer>
			</Container>
		</Section>
	)
}

export default About
