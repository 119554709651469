import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import styled from 'styled-components'
import Container from './lib/Container'
import Section from './lib/Section'
import breakpoints from '../commons/breakpoints'
import Button from './Button'
import FlexContainer from './lib/FlexContainer'
import dots from '../images/dots.png'
// import BadgesImage from '../images/badges.png'

const Hero = styled(Section)`
  min-height: 100vh;
  background: ${({ theme }) => theme.colors.copyLight};
  display: flex;
  justify-content: center;
  position: relative;
`

const HeroInner = styled(Container)`
  padding-top: 1rem;

  &:after {
    content: '';
    width: 5rem;
    height: 5rem;
    border-radius: 50%;
    background-color: rgba(226, 221, 255, 0.3);
    position: absolute;
    right: 0;
    bottom: -2.5rem;
  }

  @media ${breakpoints.device.tablet} {
    padding-top: 0;

    &:before {
      content: '';
      width: 3rem;
      height: 3rem;
      background-color: ${({ theme }) => theme.colors.white};
      position: absolute;
      right: 5%;
      top: 15%;
      transform: rotate(45deg);
    }

    &:after {
      width: 10rem;
      height: 10rem;
      bottom: -5rem;
    }
  }
`

const HeroCopy = styled.div`
  order: 2;
  margin-top: 2rem;
  text-align: center;

  @media ${breakpoints.device.tablet} {
    flex-basis: 60%;
    order: 1;
    margin-top: 0;
    text-align: left;
  }
`

const AvatarBox = styled.div`
  order: 1;
  position: relative;
  margin-bottom: 1rem;

  &:before {
    content: '';
    width: 40%;
    height: 80%;
    background-image: url(${dots});
    position: absolute;
    right: 0;
    bottom: -10%;
  }

  @media ${breakpoints.device.tablet} {
    flex-basis: 40%;
    order: 2;
    margin-bottom: 0;
  }
`

const Heading = styled.h1`
  line-height: 1.3;
  font-size: 1.8rem;

  @media ${breakpoints.device.tablet} {
    line-height: 1.5;
    font-size: 2.5rem;
  }
`

const Highlight = styled.span`
  color: ${({ theme }) => theme.colors.basePurple};
`
const CtaBox = styled.div`
  @media ${breakpoints.device.tablet} {
    margin-top: 5rem;
  }
`

const BadgesContainer = styled.div`
  position: relative;
  top: 50px;
  z-index: 2;
`
// const Badges = styled.div`
//   display: flex;
//   flex-direction: row;
//   justify-content: space-around;
// `

const HomeHero = ({ author }) => {
  const { name, jobTitle } = author
  return (
    <Hero id='home'>
      <HeroInner>
        <FlexContainer>
          <HeroCopy>
            <Heading>
              {name} <br />
              <Highlight>{jobTitle}</Highlight>
            </Heading>
            <p>
              A fullstack freelance developer with 7 years experience creating
              successful mobile and web applications.
            </p>

            <CtaBox>
              <Button pathName='#about' title='Explore More' />
            </CtaBox>
            <BadgesContainer>
              <p>Earned Upwork Badges</p>
              <StaticImage
                width={1195}
                height={196}
                src={'../images/badges.png'}
                alt='Surafel Haimanot: Rising Talent, 98% Job Success Score, Top Rated'
                style={{ marginTop: '1rem', maxWidth: '22rem' }}
              />
            </BadgesContainer>
          </HeroCopy>

          <AvatarBox>
            <StaticImage
              width={596}
              height={498}
              src='../images/surafel-h.png'
              alt='Surafel Haimanot'
              placeholder='tracedSVG'
            />
          </AvatarBox>
        </FlexContainer>
      </HeroInner>
    </Hero>
  )
}

export default HomeHero
