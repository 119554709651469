import React from 'react'
import { getImage, GatsbyImage } from 'gatsby-plugin-image'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import FlexContainer from './FlexContainer'
import StackCards from './StackCards'
import styled from 'styled-components'
import Container from './Container'
import breakpoints from './../../commons/breakpoints'
import checkmathBg from '../../images/checkmath-bg.svg'

const StyledProjectCard = styled.li`
	min-height: 100vh;
	padding: 2.5rem 0;
	text-align: center;
	display: flex;
	justify-content: center;
	color: ${({ textColor }) => textColor};
	background-color: ${({ bgColor }) => bgColor};
	background-image: ${({ title }) =>
		title === 'CheckMath' ? `url(${checkmathBg})` : 'none'};
	background-repeat: no-repeat;
	background-position: top center;

	& h3,
	h4 {
		margin-top: 1.5rem;
		color: ${({ textColor }) => textColor};
	}

	@media ${breakpoints.device.tablet} {
		background-position: bottom center;
		padding: 4rem 0;
		text-align: left;

		& h4 {
			margin-top: 2rem;
		}
	}
`

const ProjectScreens = styled.div`
	display: flex;
	padding: 3rem;
	align-items: center;
	max-width: 450px;

	@media ${breakpoints.device.tablet} {
		flex-basis: 50%;
		max-width: 600px;
		padding: 4rem;
	}
`

const ScreenOneBox = styled.div`
	z-index: 2;
	transition: 0.5s;
	order: 1;
`

const ScreenTwoBox = styled.div`
	order: 2;
	margin-left: -3rem;
	transition: 0.5s;
	z-index: 1;

	&:hover {
		z-index: 3;
		transform: scale(1.2);
	}

	&:hover + div {
		transform: scale(0.84);
	}
`

const ProjectDescription = styled.div`
	@media ${breakpoints.device.tablet} {
		flex-basis: 50%;
	}
`

const ProjectCard = ({ project }) => {
	const {
		title,
		techStack,
		bgColor,
		textColor,
		logo,
		firstScreen,
		secondScreen,
	} = project.frontmatter

	const projectLogo = getImage(logo)
	const screenOne = getImage(firstScreen)
	const screenTwo = getImage(secondScreen)

	return (
		<StyledProjectCard {...{ bgColor, textColor, title }}>
			<Container>
				<FlexContainer>
					<ProjectScreens>
						<ScreenTwoBox>
							<GatsbyImage image={screenTwo} alt={title} />
						</ScreenTwoBox>
						<ScreenOneBox>
							<GatsbyImage image={screenOne} alt={title} />
						</ScreenOneBox>
					</ProjectScreens>
					<ProjectDescription>
						<GatsbyImage image={projectLogo} alt='project logo' />
						<h3>{title}</h3>
						<MDXRenderer>{project.body}</MDXRenderer>
						<h4>Technologies</h4>
						<StackCards stack={techStack} project />
					</ProjectDescription>
				</FlexContainer>
			</Container>
		</StyledProjectCard>
	)
}

export default ProjectCard
