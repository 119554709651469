import React from 'react'

const BuddyLogo = (props) => {
	return (
		<svg
			viewBox='0 0 40 40'
			xmlns='http://www.w3.org/2000/svg'
			fillRule='evenodd'
			clipRule='evenodd'
			strokeLinejoin='round'
			strokeMiterlimit='2'>
			<path
				d='M15.996.534a3.503 3.503 0 013.501 0L33.742 8.76a3.501 3.501 0 011.751 3.032v16.451c0 1.25-.668 2.406-1.751 3.032L19.497 39.5a3.503 3.503 0 01-3.501 0A66030.71 66030.71 0 001.75 31.275 3.503 3.503 0 010 28.243V11.792A3.5 3.5 0 011.75 8.76L15.996.534z'
				fill='#0da7fe'
			/>
			<path
				d='M-.194 30.266l9.437-10.249 8.529 20.493L-.194 30.266z'
				fill='#1a67fd'
			/>
			<path
				d='M9.243 20.017L-.07 9.698l-.124 20.568 9.437-10.249z'
				fill='#0da7fe'
			/>
			<path
				d='M9.243 20.017L17.772-.477-.07 9.698l9.313 10.32z'
				fill='#05bbff'
			/>
			<path d='M17.772-.477l17.747 30.741L9.243 20.017' fill='#00c9ff' />
			<path
				d='M17.772 40.545l17.747-10.279L9.243 20.017l8.529 20.528z'
				fill='#1a86fd'
			/>
			<path d='M35.519 30.264V9.77L17.772-.477l17.747 30.741z' fill='#05bbff' />
			<path
				d='M15.181 11.945l8.595 8.595-8.595 8.596-2.253-2.253 6.342-6.343-6.342-6.342 2.253-2.253z'
				fill='#1a67fd'
				fillOpacity='.5'
			/>
			<path
				d='M23.776 19.53l-2.253-2.254-8.595 8.596 2.253 2.253 8.595-8.596z'
				fill='#d6ffff'
			/>
			<path
				d='M15.181 10.934l-2.253 2.253 8.595 8.595 2.253-2.253-8.595-8.595z'
				fill='#d6ffff'
			/>
		</svg>
	)
}

export default BuddyLogo
