import React from 'react'
const LaravelLogo = (props) => {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 102.198 105.208'>
			<path
				d='M102.14 23.8a1.665 1.665 0 01.058.43v22.584a1.647 1.647 0 01-.827 1.428L82.416 59.154v21.63a1.651 1.651 0 01-.823 1.428l-39.565 22.776a1.769 1.769 0 01-.288.119c-.037.012-.072.035-.111.045a1.657 1.657 0 01-.844 0c-.045-.012-.086-.037-.13-.054a1.711 1.711 0 01-.272-.111L.827 82.212A1.649 1.649 0 010 80.784V13.036a1.683 1.683 0 01.058-.436c.012-.047.041-.091.058-.138a1.623 1.623 0 01.1-.255 1.54 1.54 0 01.113-.146 1.677 1.677 0 01.146-.191 1.608 1.608 0 01.163-.123 1.342 1.342 0 01.181-.142L20.6.22a1.651 1.651 0 011.647 0L42.03 11.608a2.018 2.018 0 01.181.14 1.947 1.947 0 01.161.123 1.926 1.926 0 01.148.193 1.518 1.518 0 01.111.146 1.718 1.718 0 01.107.255c.016.047.045.091.058.14a1.665 1.665 0 01.058.43v42.316l16.482-9.49V24.229a1.664 1.664 0 01.064-.429c.014-.049.041-.093.058-.14a1.855 1.855 0 01.107-.255c.031-.054.076-.1.111-.146a1.694 1.694 0 01.148-.191 1.582 1.582 0 01.161-.123 1.663 1.663 0 01.181-.142l19.781-11.388a1.649 1.649 0 011.647 0L101.372 22.8a1.817 1.817 0 01.185.14c.051.041.111.078.158.123a1.926 1.926 0 01.148.193 1.246 1.246 0 01.111.146 1.633 1.633 0 01.107.255 1.176 1.176 0 01.058.14zM98.9 45.861V27.082l-6.922 3.985-9.562 5.506v18.778l16.484-9.49zm-19.779 33.97V61.04l-9.406 5.372-26.859 15.329v18.968l36.265-20.878zM3.3 15.887v63.944l36.261 20.876V81.743L20.615 71.022h-.014a75.939 75.939 0 00-.333-.255v-.006a1.568 1.568 0 01-.136-.173 1.758 1.758 0 01-.132-.169v-.006a1.313 1.313 0 01-.086-.206 1.219 1.219 0 01-.078-.185 1.576 1.576 0 01-.033-.241 1.428 1.428 0 01-.025-.185V25.377l-9.56-5.508-6.918-3.98zM21.43 3.55L4.95 13.036l16.476 9.486L37.9 13.034 21.426 3.55zM30 62.752l9.56-5.5V15.887l-6.922 3.985-9.562 5.506v41.361zm50.77-48.007l-16.478 9.486 16.478 9.486 16.476-9.488-16.476-9.484zm-1.649 21.827l-9.562-5.506-6.922-3.985v18.78l9.56 5.5 6.924 3.987V36.572zM41.205 78.891l24.169-13.8L77.456 58.2 60.99 48.72 42.032 59.632l-17.278 9.947 16.451 9.311z'
				fill='#ff2d20'
				fillRule='evenodd'
			/>
		</svg>
	)
}

export default LaravelLogo
