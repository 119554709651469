import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import styled from 'styled-components'
import Container from './lib/Container'
import Section from './lib/Section'
import SectionTitle from './lib/SectionTitle'
import ProjectCard from './lib/ProjectCard'
import Button from './Button'

const Paragraph = styled.p`
  max-width: 40rem;
`

const ProjectCards = styled.ul`
  margin-top: 3rem;
`
const LinkBox = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 1rem;
`
const FeaturedProjects = (props) => {
  const { allMdx } = useStaticQuery(graphql`
    query FeatureProjects {
      allMdx(
        filter: {
          frontmatter: { type: { eq: "project" }, order: { regex: "/[a-z]/" } }
        }
        sort: { fields: frontmatter___order, order: ASC }
        limit: 4
      ) {
        nodes {
          frontmatter {
            title
            techStack
            textColor
            bgColor
            firstScreen {
              childImageSharp {
                gatsbyImageData
              }
            }
            secondScreen {
              childImageSharp {
                gatsbyImageData
              }
            }
            logo {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
          id
          body
        }
      }
    }
  `)
  return (
    <Section id='#projects'>
      <Container>
        <SectionTitle title='Featured Projects' />
        <Paragraph>
          Succeed online with a mobile app that is fast, easy to use, and built with best practices and a passion for design. Here are some of my works.
        </Paragraph>
      </Container>
      <ProjectCards>
        {allMdx.nodes.map((project) => (
          <ProjectCard key={project.id} project={project} />
        ))}
      </ProjectCards>
      <LinkBox>
        <Button pathName='/portfolio' title='See all projects' />
      </LinkBox>
    </Section>
  )
}

export default FeaturedProjects
