import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import HomeHero from '../components/HomeHero'
import About from '../components/About'
import TechStack from './../components/TechStack'
import FeaturedProjects from '../components/FeaturedProjects'
import Testimonials from '../components/Testimonials'
import Contact from '../components/Contact'

const HomePage = ({ data }) => {
	const { title, author } = data.site.siteMetadata
	return (
		<Layout pageTitle={`${author.jobTitle} | ${title}`}>
			<HomeHero author={author} />
			<About author={author} />
			<TechStack />
			<FeaturedProjects />
			<Testimonials />
			<Contact />
		</Layout>
	)
}

export default HomePage

export const query = graphql`
	query HomePageQuery {
		site {
			siteMetadata {
				title
				author {
					jobTitle
					name
				}
			}
		}
	}
`
